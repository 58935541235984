<template>
  <el-dialog title="话费补充"
             :visible.sync="dialogVisible"
             width="50%">
    <el-select v-model="tel"
               style="width:100%"
               placeholder="请选择">
      <el-option v-for="item in list"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
      </el-option>
    </el-select>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="supplement">补充</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { luckySupplement } from '@/api/market'
export default {
  data () {
    return {
      dialogVisible: false,
      log_id: '',
      tel: '',
      list: []
    }
  },

  components: {},

  computed: {},

  methods: {
    showModal (scpoe) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.log_id = scpoe.id
        this.tel = scpoe.recharges[0].expenses_tel
        this.list = scpoe.recharges.map((item) => {
          return {
            value: item.expenses_tel,
            label: item.expenses_tel
          }
        })
      })
    },
    supplement () {
      this.$confirm('确认补充？', {
        type: 'warning'
      }).then(async () => {
        const res = await luckySupplement({
          log_id: this.log_id,
          tel: this.tel
        })
        if (res.meta.code === 0) {
          this.$message.success('补发成功')
          this.dialogVisible = false
          this.$emit('success')
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    }
  },

  created () { },

  mounted () { }

}
</script>
<style  scoped>
</style>
