<template>
  <div>
    <table-page dataName="logs"
                ref="table"
                :tabList="tabList"
                @on-success="fetchSuccess"
                :params="{ activity_id: $route.query.id }"
                :search="search"
                :request="fetchLuckyRecords">
      <template slot="button">
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="luckyLogExport_url"
                   @click="exportData(luckyLogExport_url)">导出领取记录</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="luckyQrcodesExport_url"
                   @click="exportData(luckyQrcodesExport_url)">导出参与码数据</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-permission="['ADMIN']"
                   @click="fetchStatisticals()">统计</el-button>
        <el-button icon="el-icon-upload2"
                   class="mr-15"
                   @click="importVerification()">导入已核销卡密</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="batch()">一键补发话费</el-button>
      </template>
      <el-table-column prop="user_name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="user_tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="user_id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="user_openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column prop="prize_name"
                       show-overflow-tooltip
                       label="奖品名称">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="中奖时间">
      </el-table-column>
      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="关联员工">
      </el-table-column>

      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       label="员工所属网点">
        <template slot-scope="scope">
          <span>{{
            `${scope.row.marketer_province}${scope.row.marketer_city || ''}${
              scope.row.marketer_district || ''
            }${scope.row.marketer_net || ''}`
          }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="备注">
        <template slot-scope="scope">
          <span>{{
            scope.row.status === 'NORMAL'
              ? ''
              : scope.row.err_msg === 'SUCCESS'
              ? ''
              : scope.row.err_msg
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-10"
                     v-if="
              scope.row.prize_type === 'ENVELOPE' && scope.row.status === 'FAIL'
            "
                     @click="againReceive(scope.row)">补发</el-button>
          <el-button type="text"
                     class="mr-10"
                     v-if="scope.row.prize_type === 'OPEN_AREA_VERIFY' && showBtn(scope)"
                     @click="changeNet(scope.row)">修改网点</el-button>
          <el-button type="text"
                     class="mr-10"
                     v-if="scope.row.is_supplement"
                     v-permission="['ADMIN']"
                     @click="supplement(scope.row)">补充</el-button>
          <el-button type="text"
                     v-if="
              scope.row.take_tel &&
              scope.row.prize_type === 'KIND' &&
              !scope.row.logistics_no
            "
                     @click="changeAddress(scope)">修改地址</el-button>
        </template>
      </el-table-column>
    </table-page>
    <el-dialog title="提示"
               :visible.sync="dialogVisible"
               width="40%">
      <div v-if="statistics"
           class="el-dialog-container">
        <div class="el-dialog-h1">红包</div>
        <div class="el-dialog-h2">已领取红包统计</div>
        <div>
          已领取金额：{{ statistics.receiveTotalMoney }}元； 已领取数：{{
            statistics.receiveTotal
          }}个；
        </div>
        <div class="el-dialog-h1">明细</div>
        <div v-if="statistics.prizes.length > 0">
          <div v-for="(item, index) in statistics.prizes"
               :key="index">
            <div>{{ item }}</div>
          </div>
        </div>
        <div v-else>本次活动暂无幸运包奖品</div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <SetAddress ref="setAddress"
                @on-success="$refs.table.fetch()"
                :aid="$route.query.id" />
    <Supplement ref="supplement"
                @success="$refs.table.fetch()" />

    <SetNet ref="setNet"
            :aid="$route.query.id"
            @on-success="$refs.table.fetch()" />
    <ImportVerificationKey ref="importVerificationKey"
                           :aid="$route.query.id" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import Supplement from './components/Supplement'
import SetAddress from './components/SetAddress'
import ImportVerificationKey from './components/ImportVerificationKey'
import SetNet from './components/SetNet'
import {
  fetchLuckyRecords,
  luckyReceiveEnvelope,
  fetchStatisticals,
  luckyBatchRecharge
} from '@/api/market'
export default {
  name: 'LuckyRecords',
  data () {
    return {
      fetchLuckyRecords,
      dialogVisible: false,
      luckyQrcodesExport_url: '',
      luckyLogExport_url: '',
      search: [
        { type: 'area', isDisabled: true },
        {
          type: 'input',
          name: '手机号码',
          placeholder: '请输入手机号码',
          key: 'tel',
          value: ''
        }
      ],
      statistics: null,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'SUCCESS',
        label: '领取成功'
      }, {
        value: 'PENDING',
        label: '领取中'
      }, {
        value: 'FAIL',
        label: '领取失败'
      }]
    }
  },
  components: {
    TablePage,
    Supplement,
    SetAddress,
    SetNet,
    ImportVerificationKey
  },
  methods: {
    async batch () {
      const loading = this.$loading({
        lock: true,
        text: '补发中，请勿操作',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const res = await luckyBatchRecharge({
        activity_id: this.$route.query.id
      })
      loading.close()
      if (res.meta.code === 0) {
        this.$message.success('补发成功')
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 导入核销卡密
    importVerification () {
      this.$refs.importVerificationKey.showDialog(this.$route.query.id)
    },
    showBtn (scope) {
      const { row } = scope
      const {
        role,
        province,
        city,
        district,
        net
      } = this.$store.state.user.userInfo
      switch (role) {
        case 'MANAGE_NET':
          return net === row.activity_net
        case 'MANAGE_CITY':
          return city === row.activity_city
        case 'MANAGE_DISTRICT':
          return district === row.activity_district
        case 'MANAGE_PROVINCE':
          return province === row.activity_province
        case 'ADMIN':
          return true
        default:
          return false
      }
    },
    changeNet (scope) {
      this.$refs.setNet.showDialog(scope)
    },
    changeAddress (scope) {
      this.$refs.setAddress.showDialog(scope)
    },
    supplement (scoped) {
      this.$refs.supplement.showModal(scoped)
    },
    async fetchStatisticals () {
      const res = await fetchStatisticals({
        activity_id: this.$route.query.id
      })
      if (res.meta.code === 0) {
        this.statistics = res.data
        this.dialogVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async againReceive (item) {
      this.$confirm('确认补发？', {
        type: 'warning'
      }).then(async () => {
        const res = await luckyReceiveEnvelope({
          log_id: item.id
        })
        if (res.meta.code === 0) {
          this.$message.success('补发成功')
          this.dialogVisible = false
          this.$refs.table.fetch()
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    },
    fetchSuccess (e) {
      this.luckyQrcodesExport_url = e.luckyQrcodesExport_url
      this.luckyLogExport_url = e.luckyLogExport_url
    },
    exportData (url) {
      window.open(url, '_blank')
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.el-dialog-container {
  div {
    margin-top: 10px;
  }
  .el-dialog-h1 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
  }
  .el-dialog-h2 {
    font-size: 14px;
    font-weight: bolder;
    color: #494949;
  }
}
</style>
