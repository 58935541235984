<template>
  <div class="">
    <el-dialog title="设置"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="预约网点"
                      prop="net">
          <Area v-model="areaAddress"
                @change="areaChange" />
        </el-form-item>
        <el-form-item class="tr">
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">立即{{form.id!==''?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Area from '@/components/Area'
import { luckyChangeNet } from '@/api/market'
export default {
  props: ['aid'],
  data () {
    return {
      areaAddress: {},
      options: [],
      dialogVisible: false,
      imgList: [],
      form: {
        id: '',
        province: '',
        city: '',
        district: '',
        net: '',
        activity_id: ''
      },
      rules: {
        net: [
          { required: true, message: '请选择到网点', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area
  },
  methods: {

    async areaChange (address) {
      this.form.new_network_id = ''
      this.form.province = address.province
      this.form.city = address.city
      this.form.district = address.district
      this.form.net = address.net
    },
    showDialog (scoped) {
      this.dialogVisible = true
      if (scoped.areaNet) {
        this.areaAddress = {
          province: scoped.areaNet.province,
          city: scoped.areaNet.city,
          district: scoped.areaNet.district,
          net: scoped.areaNet.net
        }
      } else {
        this.areaAddress = {
          province: '广东',
          city: '广州',
          district: '',
          net: ''
        }
      }

      this.$nextTick(() => {
        if (scoped) {
          const formData = JSON.parse(JSON.stringify(scoped))
          this.form = {
            id: formData.id,
            province: formData.areaNet.province,
            city: formData.areaNet.city,
            district: formData.areaNet.district,
            net: formData.areaNet.net,
            activity_id: this.aid
          }
        }
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await luckyChangeNet(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
