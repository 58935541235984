<template>
  <div class="">
    <el-dialog title="导入核销卡密"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <span>请选择卡套id：</span>
      <el-select v-model="card_id"
                 placeholder="请选择">
        <el-option v-for="item in options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
      <FileUpload v-if="card_id"
                  :params="{import_type:'card_key_verify_data',carry_data:{activity_id:activity_id,type:'LUCKY',card_id:card_id}}"
                  class="ml-15">导入核销卡密</FileUpload>
    </el-dialog>
  </div>
</template>

<script>
import { luckyEditAddress, getLuckyCardIds } from '@/api/market'
import FileUpload from '@/components/FileUploadAsync'
export default {
  props: ['aid'],
  data () {
    return {
      areaAddress: {},
      options: [],
      dialogVisible: false,
      imgList: [],
      card_id: '',
      activity_id: '',
      rules: {
        take_name: [
          { required: true, message: '请输入收件人名称', trigger: 'change' }
        ],
        take_tel: [
          { required: true, message: '请输入收件人手机号', trigger: 'change' }
        ],
        take_area: [
          { required: true, message: '请选择省市区', trigger: 'change' }
        ],
        take_address: [
          { required: true, message: '请输入收件地址', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    FileUpload
  },
  methods: {
    async getCardIds () {
      const res = await getLuckyCardIds({
        activity_id: this.activity_id
      })
      if (res.meta.code === 0) {
        this.options = res.data.cardList.map(item => {
          return {
            value: item.id,
            label: `${item.name}-${item.id}`
          }
        })
      }
    },
    async areaChange (address) {
      this.form.take_province = address.province
      this.form.take_city = address.city
      this.form.take_area = address.district
    },
    showDialog (id) {
      this.card_id = ''
      this.dialogVisible = true
      this.activity_id = id
      this.getCardIds()
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await luckyEditAddress({ ...this.form, activity_id: this.aid })
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
