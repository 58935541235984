<template>
  <div class="">
    <el-dialog title="设置选项"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="省市区选择"
                      prop="take_area">
          <Area v-model="areaAddress"
                @change="areaChange" />
        </el-form-item>
        <el-form-item label="收件人"
                      prop="take_name">
          <el-input type="text"
                    placeholder="请输入收件人"
                    v-model="form.take_name"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号"
                      prop="take_tel">
          <el-input type="text"
                    placeholder="请输入收件人手机号"
                    v-model="form.take_tel"></el-input>
        </el-form-item>
        <el-form-item label="收件地址"
                      prop="take_address">
          <el-input type="text"
                    placeholder="请输入收件地址"
                    v-model="form.take_address"></el-input>
        </el-form-item>
        <el-form-item class="tr">
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">立即{{form.id!==''?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Area from '@/components/Address'
import { luckyEditAddress } from '@/api/market'
export default {
  props: ['aid'],
  data () {
    return {
      areaAddress: {},
      options: [],
      dialogVisible: false,
      imgList: [],
      form: {
        id: '',
        take_name: '',
        take_tel: '',
        take_province: '',
        take_city: '',
        take_area: '',
        take_address: '',
        logistics_no: ''
      },
      rules: {
        take_name: [
          { required: true, message: '请输入收件人名称', trigger: 'change' }
        ],
        take_tel: [
          { required: true, message: '请输入收件人手机号', trigger: 'change' }
        ],
        take_area: [
          { required: true, message: '请选择省市区', trigger: 'change' }
        ],
        take_address: [
          { required: true, message: '请输入收件地址', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area
  },
  methods: {
    async areaChange (address) {
      this.form.take_province = address.province
      this.form.take_city = address.city
      this.form.take_area = address.district
    },
    showDialog (scoped) {
      console.log(scoped)
      const { row } = scoped
      const formData = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
      this.areaAddress = {
        province: scoped.row.take_province,
        city: scoped.row.take_city,
        district: scoped.row.take_area
      }

      this.$nextTick(() => {
        if (scoped.row) {
          this.form = {
            id: formData.id,
            take_name: formData.take_name,
            take_tel: formData.take_tel,
            take_province: formData.take_province,
            take_city: formData.take_city,
            take_area: formData.take_area,
            take_address: formData.take_address,
            logistics_no: formData.logistics_no
          }
        }
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await luckyEditAddress({ ...this.form, activity_id: this.aid })
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
